<template>
  <b-row>
    <b-col md="5">
      <b-card class="text-center">
        <b-avatar
          size="150"
          variant="light-primary"
          :src="photo"
          class="badge-minimal"
          badge-variant="success"
        />
        <b-card-text class="font-weight-bolder mt-20 mb-auto text-capitalize">{{
          user.name
        }}</b-card-text>
        <b-badge variant="light-primary text-capitalize">{{
          user.position_name
        }}</b-badge>
        <b-row class="mt-20">
          <b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              block
              v-b-modal.modal-upload-photo
            >
              Upload Photo
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              v-b-modal.modal-change-password
            >
              Change Password
            </b-button>

            <!-- modal upload photo -->
            <b-modal
              id="modal-upload-photo"
              title="Upload Photo"
              ok-title="Change Photo"
              cancel-variant="outline-secondary"
              hide-footer
            >
              <validation-observer ref="uploadPhoto">
                <b-form ref="form" @submit.prevent>
                  <b-form-group
                    :state="errors"
                    label="Photo"
                    label-for="Photo"
                    invalid-feedback="Photo is required"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Photo"
                      rules="required"
                    >
                      <image-uploader
                        :preview="true"
                        :className="[
                          'fileinput',
                          { 'fileinput--loaded': hasImage },
                        ]"
                        :debug="0"
                        :autoRotate="true"
                        accept="image/*"
                        doNotResize="['gif', 'svg']"
                        outputFormat="string"
                        @input="setImage"
                      ></image-uploader>

                      <!-- <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        block
                                        v-b-modal.modal-upload-photo
                                        for="#test"
                                        >
                                        Browse
                                    </b-button> -->
                      <small class="text-danger">{{
                        errors[0] || errMessageImage
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-row>
                    <b-col>
                      <b-button
                        class="mt-1"
                        type="submit"
                        variant="primary"
                        block
                        @click="uploadPhoto()"
                      >
                        Upload Photo
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-modal>
            <!-- end modal upload photo -->

            <!-- modal change password -->
            <b-modal
              id="modal-change-password"
              title="Change Password Form"
              ok-title="Change Password"
              cancel-variant="outline-secondary"
              hide-footer
            >
              <validation-observer ref="loginValidation">
                <b-form ref="form" @submit.prevent>
                  <b-form-group
                    :state="errors"
                    label="Password"
                    label-for="Password"
                    invalid-feedback="Password is required"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-form-input
                        id="Password"
                        name="Password"
                        type="password"
                        v-model="form.password"
                        :state="errors.length > 0 ? false : null"
                        placeholder="********"
                        required="required"
                      />
                      <small class="text-danger">{{
                        errors[0] || errMessagePassword
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    :state="errors"
                    label="Password Confirmation"
                    label-for="PasswordConfirmation"
                    invalid-feedback="Password Confirmation is required"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="PasswordConfirmation"
                      rules="required"
                    >
                      <b-form-input
                        id="PasswordConfirmation"
                        name="PasswordConfirmation"
                        type="password"
                        v-model="form.password_confirmation"
                        :state="errors.length > 0 ? false : null"
                        placeholder="********"
                        required="required"
                      />
                      <small class="text-danger">{{
                        errors[0] || errMessagePassword
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-row>
                    <b-col>
                      <b-button
                        class="mt-3"
                        type="submit"
                        variant="primary"
                        block
                        @click="formChangePassword()"
                      >
                        change Password
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-modal>
            <!-- end modal change password -->
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col>
      <b-card title="Details" class="text-center">
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >NIP :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{ user.nip }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >NIK :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{ user.nik }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Name :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{ user.name }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Birthplace, Birthdate :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right"
              >{{ user.birthplace }}, {{ user.birthdate }}</b-card-text
            >
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Address :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{
              has_address.address
            }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Postal Code :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{
              has_address.postal_code
            }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Village :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{
              has_address.village
            }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >District :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{
              has_address.district
            }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >City :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{ has_address.city }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Province :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{
              has_address.province
            }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Country :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{
              has_address.country
            }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Phone :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{ user.phone }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Email :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{ user.email }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Organization :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{
              user.organization_name
            }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Division :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{
              user.division_name
            }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Department :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{
              user.department_name
            }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Job Position :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{
              user.position_name
            }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Employee Status :</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right">{{
              user.status_type_name
            }}</b-card-text>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-card-text class="text-left font-weight-bolder"
              >Status</b-card-text
            >
          </b-col>
          <b-col>
            <b-card-text class="text-right" v-if="user.status == 1"
              >Active</b-card-text
            >
            <b-card-text class="text-right" v-else>Non-active</b-card-text>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BAvatar,
  BImg,
  BFormFile,
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BBadge,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ImageUploader from "vue-image-upload-resize";
import { $themeConfig } from "@themeConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
    ImageUploader,
    BAvatar,
    BImg,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BForm,
    BFormFile,
    BModal,
    BButton,
    BBadge,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: [],
      has_address: [],
      form: {
        password: "",
        password_confirmation: "",
      },
      hasImage: "",
      image: "",
      photo: "",
      errMessageImage: "",
      errMessagePassword: "",
      errors: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  mounted() {
    this.getPhoto();
    this.getDetails();
  },
  methods: {
    getPhoto() {
      this.$http
        .get("user")
        .then((response) => {
          const { baseUrl } = $themeConfig.app;
          this.photo = baseUrl;
          this.photo += "storage/profil_photos/";
          if (response.data.data.image === "" || null) {
            this.photo += "nophoto.png";
          } else {
            this.photo += response.data.data.image;
          }
          // console.log(this.photo)
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getDetails() {
      this.$http
        .get("user")
        .then((response) => {
          // console.log(response.data.data)
          this.user = response.data.data;
          this.has_address = response.data.data.has_address;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    formChangePassword() {
      this.$http
        .post("user/update-password", {
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success update Password",
              variant: "success",
            },
          });
          location.href = "/profile";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessagePassword = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessagePassword,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    setImage: function (file) {
      this.hasImage = true;
      this.image = file;
    },
    uploadPhoto() {
      this.$http
        .post("user/update-photo", {
          image: this.image,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success upload Photo",
              variant: "success",
            },
          });
          location.href = "/profile";
          // console.log(response.data)
        })
        .catch((errors) => {
          this.errMessageImage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessageImage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style>
.mt-20 {
  margin-top: 20px;
}
</style>